import React from "react";
import * as Styles from "./Subtitle.module.css";

export interface SubtitleProps {
  title: string;
  color?: string;
  weight?: number;
  margin?: string;
  padding?: string;
  width?: string;
  maxWidth?: string;
  alignment?: "left" | "center" | "right" | "justify";
  style?: "normal" | "italic" | "oblique";
}

export const Subtitle = ({
  title = "Subtitulo",
  color = "var(--text)",
  weight = 500,
  margin = "auto",
  padding = "",
  width = "auto",
  maxWidth = "",
  alignment = "center",
  style = "normal",
  ...props
}: SubtitleProps) => {
  const StyleProps = {
    color: color,
    fontWeight: weight,
    fontStyle: style,
    textAlign: alignment,
    margin: margin,
    padding: padding,
    width: width,
    maxWidth: maxWidth,
  };

  return (
    <h1 className={Styles.text} style={StyleProps} {...props} title={title}>
      {title}
    </h1>
  );
};
