import React, { useState } from "react";
import * as Styles from "./ScrollButton.module.css";
import { StaticImage } from "gatsby-plugin-image";

export const ScrollButton = () => {
  const [visible, setVisible] = useState(false);
  const { button, hidden, icon } = Styles;

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  try {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", toggleVisible);
    }
  } catch (error) {
    console.log("error: " + error);
  }

  return (
    <button className={visible ? button : hidden} onClick={() => scrollToTop()}>
      <div className={icon}>
        <StaticImage
          src={`../../../images/up.png`}
          alt=""
          placeholder="blurred"
          quality={100}
          layout="fixed"
          width={24}
          height={13.8}
        />
      </div>
    </button>
  );
};
