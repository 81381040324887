exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abarrotes-tsx": () => import("./../../../src/pages/abarrotes.tsx" /* webpackChunkName: "component---src-pages-abarrotes-tsx" */),
  "component---src-pages-aviso-de-privacidad-tsx": () => import("./../../../src/pages/aviso-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-cuidado-personal-tsx": () => import("./../../../src/pages/cuidado-personal.tsx" /* webpackChunkName: "component---src-pages-cuidado-personal-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insumos-hoteleros-tsx": () => import("./../../../src/pages/insumos-hoteleros.tsx" /* webpackChunkName: "component---src-pages-insumos-hoteleros-tsx" */),
  "component---src-pages-limpieza-y-cuidado-del-hogar-tsx": () => import("./../../../src/pages/limpieza-y-cuidado-del-hogar.tsx" /* webpackChunkName: "component---src-pages-limpieza-y-cuidado-del-hogar-tsx" */),
  "component---src-pages-medicamentos-y-material-de-curacion-tsx": () => import("./../../../src/pages/medicamentos-y-material-de-curacion.tsx" /* webpackChunkName: "component---src-pages-medicamentos-y-material-de-curacion-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-terminos-y-condiciones-tsx": () => import("./../../../src/pages/terminos-y-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-tsx" */)
}

