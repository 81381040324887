import React, { ReactNode } from "react";
import * as Styles from "./Grid.module.css";

interface GridProps {
  children: ReactNode;
  customGrid?: string;
  columns?: number;
  alignment?: string;
  rows?: number;
  gap?: number;
  rowGap?: number;
  customColumns?: string;
  autoColumns?: boolean;
  size?: string;
  marginTop?: number;
  marginBottom?: number;
  onClick?: () => void;
}

export const Grid = ({
  customGrid = "",
  columns = 2,
  alignment = "centered",
  rows = 1,
  gap = 30,
  rowGap = gap,
  customColumns = "",
  size = "100%",
  marginTop = 0,
  marginBottom = 0,
  onClick,
  children,
  ...props
}: GridProps) => {
  const StyleProps = {
    gridTemplateColumns: customGrid
      ? ""
      : customColumns
      ? customColumns
      : `repeat(${columns}, 1fr)`,
    gridGap: `${gap}px`,
    rowGap: `${rowGap}px`,
    width: `${size}`,
    marginTop: `${marginTop}px`,
    marginBottom: `${marginBottom}px`,
  };

  return (
    <div
      className={`${Styles.grid} ${Styles[customGrid]} ${Styles[alignment]}`}
      style={StyleProps}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  );
};
