import React from "react";
import * as Styles from "./Text.module.css";

export interface TextProps {
  label: string;
  color?: string;
  size?: string;
  weight?: number;
  margin?: string;
  padding?: string;
  width?: string;
  maxWidth?: string;
  alignment?: "left" | "center" | "right" | "justify";
  style?: "normal" | "italic" | "oblique";
}

export const Text = ({
  label = "Texto",
  size = "",
  color = "var(--text)",
  weight = 400,
  margin = "auto",
  padding = "",
  width = "auto",
  maxWidth = "",
  alignment = "justify",
  style = "normal",
  ...props
}: TextProps) => {
  const StyleProps = {
    color: color,
    fontSize: size,
    fontWeight: weight,
    fontStyle: style,
    textAlign: alignment,
    margin: margin,
    padding: padding,
    width: width,
    maxWidth: maxWidth,
  };

  return (
    <p className={`${Styles.text}`} style={StyleProps} {...props}>
      {label}
    </p>
  );
};
