import * as React from "react";
import * as Styles from "./Navbar.module.css";
import { globalHistory } from "@reach/router";
import { Grid } from "@stories/Containers/Grid/Grid";
import { Nav } from "@stories/Texts/Nav/Nav";
import { navigation } from "@data/navigation";
import { StaticImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Navbar = () => {
  const { navbar, navbar_top, content, logo, logo_top } = Styles;
  const path = globalHistory.location.pathname;
  const [visible, setVisible] = React.useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  try {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", toggleVisible);
    }
  } catch (error) {
    console.log("error: " + error);
  }

  const navStyle = path != "/" ? navbar : visible ? navbar : navbar_top;
  const logoStyle = path != "/" ? logo : visible ? logo : logo_top;

  return (
    <nav className={navStyle}>
      <div className={content}>
        <Grid customGrid="navbar">
          <div className={logoStyle}>
            <AniLink
              swipe
              direction="left"
              duration={0.4}
              bg="var(--secondary)"
              to="/"
            >
              <StaticImage
                src={`../../../../images/logo.png`}
                alt=""
                placeholder="blurred"
                quality={100}
                layout="constrained"
              />
            </AniLink>
          </div>
          <div />
          <Grid customGrid="third">
            {navigation.map((navigation) => {
              const linkStyle =
                path != "/" ? "var(--black)" : visible ? "var(--black)" : "";
              return (
                <Nav
                  url={navigation.url}
                  name={navigation.name}
                  color={linkStyle}
                />
              );
            })}
          </Grid>
        </Grid>
      </div>
    </nav>
  );
};

export default Navbar;
