import React, { ReactNode } from "react";
import * as Styles from "./Section.module.css";

interface SectionProps {
  children: ReactNode;
  marginTop?: number;
  marginBottom?: number;
}

export const Section = ({
  children,
  marginTop,
  marginBottom,
  ...props
}: SectionProps) => {
  const StyleProps = {
    marginTop: `${marginTop}px`,
    marginBottom: `${marginBottom}px`,
  };

  return (
    <section className={Styles.section} style={StyleProps} {...props}>
      {children}
    </section>
  );
};
