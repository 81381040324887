import React from "react";
import * as Styles from "./Nav.module.css";
import { globalHistory } from "@reach/router";
import AniLink from "gatsby-plugin-transition-link/AniLink";

interface NavProps {
  url: string;
  name: string;
  color?: string;
  onClick?: () => void;
}

export const Nav = ({
  url,
  name,
  color = "var(--text)",
  onClick,
}: NavProps) => {
  const { container, text } = Styles;
  const path = globalHistory.location.pathname;
  const StyleProps = {
    color: color,
  };

  return (
    <div className={container}>
      <AniLink
        swipe
        direction="left"
        duration={0.4}
        bg="var(--secondary)"
        className={text}
        style={StyleProps}
        to={url}
        onClick={onClick}
      >
        {name}
      </AniLink>
    </div>
  );
};
