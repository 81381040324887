import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import Layout from "./src/components/Layout/Layout";

const googleAnalyticsId = process.env.GATSBY_GOOGLE_ANALYTICS_ID;

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}: any) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  if (typeof window !== "undefined") {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    document.head.appendChild(script);

    const scriptInit = document.createElement("script");
    scriptInit.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${googleAnalyticsId}');
    `;
    document.head.appendChild(scriptInit);
  }
};
