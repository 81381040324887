import * as React from "react";
import { ReactNode } from "react";
import PropTypes from "prop-types";

import Header from "./Header";
import "@styles/globals.css";
import "@styles/embla.css";
import Navbar from "./Navigation/Navbar/Navbar";
import Footer from "./Navigation/Footer/Footer";
import { ScrollButton } from "@stories/Buttons/ScrollButton/ScrollButton";
import { BurgerNav } from "./Navigation/BurgerNav/BurgerNav";

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Header />
      <Navbar />
      <BurgerNav />
      <main>{children}</main>
      <ScrollButton />
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
