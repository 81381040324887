import * as React from "react";
import * as Styles from "./BurgerNav.module.css";
import { globalHistory } from "@reach/router";
import { StaticImage } from "gatsby-plugin-image";
import { Nav } from "@stories/Texts/Nav/Nav";
import { Link } from "gatsby";
import { FaBars } from "@stories/Buttons/FaBars/FaBars";
import { Section } from "@stories/Containers/Section/Section";

export const BurgerNav = () => {
  const {
    navbar,
    navbar_top,
    logo_top,
    modal,
    modal_ribbon,
    modal_hide,
    logo,
    field,
  } = Styles;
  const path = globalHistory.location.pathname;
  const [isOpen, setIsOpen] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  try {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", toggleVisible);
    }
  } catch (error) {
    console.log("error: " + error);
  }

  const navStyle = path != "/" ? navbar : visible ? navbar : navbar_top;
  const logoStyle = path != "/" ? logo : visible ? logo : logo_top;

  return (
    <nav className={navStyle}>
      <Link to="/" className={logoStyle}>
        <StaticImage
          src={`../../../../images/logo.png`}
          alt=""
          placeholder="blurred"
          quality={100}
          layout="constrained"
        />
      </Link>
      <FaBars state={isOpen} onClick={() => setIsOpen(!isOpen)} />
      {isOpen && (
        <div className={modal_ribbon} onClick={() => setIsOpen(!isOpen)} />
      )}
      <div className={isOpen ? modal : modal_hide}>
        <Section marginTop={25}>
          <div className={field}>
            <Nav url="/" name="Inicio" onClick={() => setIsOpen(!isOpen)} />
          </div>
          <div className={field}>
            <Nav
              url="/nosotros"
              name="Nosotros"
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
          <div className={field}>
            <Nav
              url="/contacto"
              name="Contacto"
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
        </Section>
      </div>
    </nav>
  );
};
