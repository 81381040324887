import React from "react";
import { globalHistory } from "@reach/router";
import * as Styles from "./FaBars.module.css";

interface FaBarsProps {
  state: boolean;
  onClick?: () => void;
}

export const FaBars = ({ state, onClick, ...props }: FaBarsProps) => {
  const { burgermenu, fa_bar, fa_bar_top, fa_bar_close, fa_bar_close_top } =
    Styles;
  const path = globalHistory.location.pathname;
  const [visible, setVisible] = React.useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  try {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", toggleVisible);
    }
  } catch (error) {
    console.log("error: " + error);
  }

  const fa_bars_close =
    path != "/" ? fa_bar_close : visible ? fa_bar_close : fa_bar_close;
  const fa_bars = path != "/" ? fa_bar : visible ? fa_bar : fa_bar_top;

  return (
    <button className={burgermenu} onClick={onClick} {...props}>
      <span className={state ? fa_bars_close : fa_bars} />
    </button>
  );
};
