import * as React from "react";
import * as Styles from "./Social.module.css";
import { Grid } from "@stories/Containers/Grid/Grid";
import { StaticImage } from "gatsby-plugin-image";

export const Social = () => {
  const { container, button, mail } = Styles;

  return (
    <div className={container}>
      <Grid customGrid="social" size="200px" gap={15}>
        <a
          href="https://www.facebook.com/profile.php?id=61555402811429"
          target="_blank"
          className={button}
        >
          <StaticImage
            src={`../../../../../images/facebook.png`}
            alt=""
            placeholder="blurred"
            quality={100}
            layout="constrained"
          />
        </a>
        <a
          href="https://www.instagram.com/agsamo_mx/"
          target="_blank"
          className={button}
        >
          <StaticImage
            src={`../../../../../images/instagram.png`}
            alt=""
            placeholder="blurred"
            quality={100}
            layout="constrained"
          />
        </a>
        <a
          href="https://wa.me/+525620311424?text=Quisiera%20Mas%20Informacion..."
          target="_blank"
          className={button}
        >
          <StaticImage
            src={`../../../../../images/whatsapp.png`}
            alt=""
            placeholder="blurred"
            quality={100}
            layout="constrained"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/89550598/admin/feed/posts/"
          target="_blank"
          className={button}
        >
          <StaticImage
            src={`../../../../../images/linkedin.png`}
            alt=""
            placeholder="blurred"
            quality={100}
            layout="constrained"
          />
        </a>
      </Grid>
      <a
        className={mail}
        href="mailto:adrianzp@agsamo.com?subject=Solicitud%20de%20Informes"
        target="_blank"
      >
        adrianzp@agsamo.com
      </a>
    </div>
  );
};
