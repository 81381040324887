import * as React from "react";
import * as Styles from "./Footer.module.css";
import { Text } from "@stories/Texts/Text/Text";
import { Section } from "@stories/Containers/Section/Section";
import { Grid } from "@stories/Containers/Grid/Grid";
import { Subtitle } from "@stories/Texts/Subtitle/Subtitle";
import { Link } from "gatsby";
import { maps } from "@data/map";
import { Social } from "./Social/Social";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
  const { footer, container, button, ribbon, whatsapp } = Styles;
  return (
    <footer className={footer}>
      <a
        href="https://wa.me/+525620311424?text=Quisiera%20Mas%20Informacion..."
        target="_blank"
        className={whatsapp}
      >
        <StaticImage
          src={`../../../../images/wa-color.png`}
          alt=""
          placeholder="blurred"
          quality={100}
          layout="constrained"
        />
      </a>
      <Section>
        <Grid customGrid="footer">
          <div>
            <Subtitle
              title="Enlaces Útiles"
              color="var(--white)"
              alignment="left"
              margin="20px auto 10px"
            />
            {maps.map((map) => {
              return (
                <div className={container}>
                  <Link to={map.url} target="_blank" className={button}>
                    {map.label}
                  </Link>
                </div>
              );
            })}
          </div>
          <div>
            <Subtitle
              title="AGSAMO®"
              color="var(--white)"
              alignment="left"
              margin="20px auto 10px"
            />
            <Text
              label="es una empresa orgullosamente mexicana especializada en la 
            comercialización y distribución de productos a nivel nacional."
              color="var(--white)"
              alignment="justify"
            />
          </div>
          <div />
          <Social />
        </Grid>
      </Section>
      <div className={ribbon}>
        <Text label="Todos los derechos reservados, AGSAMO®" />
      </div>
    </footer>
  );
};

export default Footer;
