// extracted by mini-css-extract-plugin
export var catalog = "Grid-module--catalog--0cc53";
export var cedis = "Grid-module--cedis--360a4";
export var centered = "Grid-module--centered--bcdc6";
export var contact = "Grid-module--contact--a5b03";
export var footer = "Grid-module--footer--8ed6a";
export var grid = "Grid-module--grid--e96cf";
export var icons = "Grid-module--icons--0f0e1";
export var index = "Grid-module--index--d35d9";
export var left = "Grid-module--left--204a4";
export var mision = "Grid-module--mision--53816";
export var navbar = "Grid-module--navbar--5712d";
export var responsive = "Grid-module--responsive--8fd3f";
export var right = "Grid-module--right--f2b0d";
export var social = "Grid-module--social--6dd37";
export var third = "Grid-module--third--cdb86";
export var title = "Grid-module--title--b0a22";
export var two = "Grid-module--two--8d54d";
export var vision = "Grid-module--vision--5cbec";