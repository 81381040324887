import { IMap } from "../types/IMap";

export const maps: IMap[] = [
  {
    label: "Inicio",
    url: "/",
  },
  {
    label: "Contáctanos",
    url: "/contacto/",
  },
  { label: "Legal", url: "/terminos-y-condiciones/" },
  { label: "Política de privacidad", url: "/aviso-de-privacidad/" },
];
